import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './store';
import i18n from './translation/i18n';
import App from './App';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

Sentry.init({
  dsn: 'https://b42c83df8f51414aa71d986ac00ec3a1@o1112292.ingest.sentry.io/6158232',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: true,
});

i18n.on('initialized', renderApp);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const isDev = process.env.NODE_ENV === 'development';

function renderApp() {
  const root = document.getElementById('root');

  if (root) {
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <App />
              </Router>

              {isDev && (
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="bottom-right"
                />
              )}
            </QueryClientProvider>
          </Provider>
        </I18nextProvider>
      </React.StrictMode>
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
