import Request from '../config';
import * as Sentry from '@sentry/react';
import { ListParams } from 'src/models/common';
import { CheckPhoneData } from 'src/store/actions';

const requestApi = new Request({ url: process.env.REACT_APP_BASE_URL_ACCOUNT });
const requestApiWallet = new Request({
  url: process.env.REACT_APP_BASE_URL_TRANSFER,
});
const requestApiConfig = new Request({
  url: process.env.REACT_APP_BASE_URL_CONFIG,
});

interface LoginData {
  phoneNumber: string;
  password?: string;
  country: {
    phoneCode: string;
  };
}

interface VerifyOTPData {
  phoneNumber: string;
  otpCode: string;
  country: {
    phoneCode: string;
  };
}

interface VerifyPINData {
  phoneNumber: string;
  otp: string;
  pin: string;
  deviceCode: string;
  country: {
    phoneCode: string;
  };
}

const handleCheckPhone = async (data: CheckPhoneData): Promise<any[]> => {
  const url = '/Account/LoginInfo';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleLogin = async (data: LoginData): Promise<any> => {
  const url = '/Account/LoginAdmin';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCheckUser = async (data: any): Promise<any> => {
  const url = '/Account/CheckUser';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};


const handleCheckAgent = async (data: any): Promise<any> => {
  // const url = '/Account/CheckUser';
  const url = '/Account/VerifyPassword';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const sendOTP = async (data: LoginData): Promise<any> => {
  const url = '/Sms/SendOTP';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const verifyOTPService = async (data: VerifyOTPData): Promise<any> => {
  const url = '/Sms/VerifyOTP';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const verifyPINService = async (data: VerifyPINData): Promise<any> => {
  // const url = '/Account/LoginUser';
  const url = '/Account/LoginUserWithUsername';
  try {
    return await requestApi.loginAgent(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleChangePassword = async (data: Object): Promise<any[]> => {
  try {
    return await requestApi.post('/Account/UpdatePassword', data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListUserScore = async (data: ListParams): Promise<any[]> => {
  const url = '/User/GetListAndRank';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditUserScore = async (data: Object): Promise<any[]> => {
  try {
    return await requestApi.post('/UserScore/update', data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUpdateUserRole = async (data: Object): Promise<any[]> => {
  const url = '/User/UpdateUserRoles';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditUserPriority = async (data: Object): Promise<any[]> => {
  const url = '/User/priority/update';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUserPriority = async (params: ListParams): Promise<any[]> => {
  const url = '/User/priority/agents';
  try {
    return await requestApi.get(url, { params });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleChoosePriority = async (data: Object): Promise<any[]> => {
  const url = '/User/priority/settings/update';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListUser = async (data: Object): Promise<any> => {
  const url = '/User/GetUsers';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUserDetail = async (userId: number): Promise<any> => {
  const url = `/User/GetUserDetails?uId=${userId}`;
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllWallet = async (userId: number): Promise<any> => {
  const url = `/Wallet/GetAllWalletsByUserId?userId=${userId}`;
  try {
    return await requestApiWallet.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleChangeUserBalance = async (data: any): Promise<any> => {
  const url = '/Wallet/Balance/Change';
  try {
    return await requestApiWallet.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleUnLockUser = async (data: any): Promise<any> => {
  const url = `/User/UnlockUser?userId=${data.userId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleReactiveUser = async (data: any): Promise<any> => {
  const url = `/User/ReactivateUser`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleResetPin = async (data: any): Promise<any> => {
  const url = `/User/ResetPINADMIN?userId=${data}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleResetPassword = async (data: any): Promise<any> => {
  const url = `/User/AdminResetPassword?userId=${data}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreatePin = async (data: any): Promise<any> => {
  const url = `/User/CreateLoginOTP?userId=${data}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeactivate = async (data: any): Promise<any> => {
  const url = `/User/DeactivateUser`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateAdmin = async (data: any): Promise<any> => {
  const url = `/User/create/admin`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListLanguageText = async (data: any): Promise<any> => {
  const url = '/LanguageText/search';
  try {
    return await requestApiConfig.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleEditLanguageText = async (data: any): Promise<any> => {
  const url = '/LanguageText/update';
  try {
    return await requestApiConfig.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCreateLanguageText = async (data: any): Promise<any> => {
  const url = '/LanguageText/create';
  try {
    return await requestApiConfig.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleDeleteLanguageText = async (languageKey: string): Promise<any> => {
  const url = `/LanguageText/Delete?languageKey=${languageKey}`;
  try {
    return await requestApiConfig.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetListUserDeactivated = async (data: ListParams): Promise<any> => {
  const url = '/User/GetDeactiveUsers';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
const handeleUploadAvatar = async (data: any): Promise<any> => {
  const url = `/Gallery/UploadAdminAvatar`;
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export interface DataEditUserByAdmin {
  userId: string | undefined;
  newEmail: string;
  newCountryId: number;
}
const handleEditUserByAdmin = async (
  data: DataEditUserByAdmin
): Promise<any> => {
  const url = '/User/EditUser';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUserStatusHistoryDeactivate = async (
  userId: string
): Promise<any> => {
  const url = `/User/UserStatusHistory?userId=${userId}`;
  try {
    return await requestApi.post(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleVerifyPINByAgent = async (data: any): Promise<any> => {
  const url = `/Account/PIN/Verify`;
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetLanguageForBO = async (data: any): Promise<any> => {
  const url = `/Language/GetLanguageForBO?iso=${data}`;
  try {
    return await requestApiConfig.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleSetUserLanguage = async (data: Object): Promise<any[]> => {
  try {
    return await requestApi.post(`/User/SetLanguage?languageId=${data}`);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleCheckPasswordAdmin = async (data: LoginData): Promise<any> => {
  const url = '/Account/CheckLoginAdmin';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetActiveCryptoUsers = async (): Promise<any> => {
  const url = '/User/GetUserActiveCrypto';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetKYCStatus = async (): Promise<any> => {
  const url = '/User/GetKYCStatus';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetAllKYC = async (): Promise<any> => {
  const url = '/User/GetAllKYCByUser';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleApplyKYCUserInfo = async (data: any): Promise<any> => {
  const url = '/User/ApplyKYCUserInfo';
  try {
    return await requestApi.post(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleApplyKYCAddress = async (data: any): Promise<any> => {
  const url = '/User/ApplyKYCAddress';
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleApplyKYCDocs = async (data: any): Promise<any> => {
  const url = '/User/ApplyKYCDocs';
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleApplyKYCBankInfo = async (data: any): Promise<any> => {
  const url = '/User/ApplyKYCBankInfo';
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleApplyKycDeclaration = async (data: any): Promise<any> => {
  const url = '/User/ApplyKycDeclaration';
  try {
    return await requestApi.postFile(url, data);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const handleGetUser = async (): Promise<any> => {
  const url = '/User/Get';
  try {
    return await requestApi.get(url);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export {
  // handleGetLanguage,
  handleCheckPhone,
  handleLogin,
  handleChangePassword,
  handleGetListUserScore,
  handleEditUserScore,
  handleUserPriority,
  handleEditUserPriority,
  handleUpdateUserRole,
  handleChoosePriority,
  handleGetListUser,
  handleGetUserDetail,
  handleGetAllWallet,
  handleChangeUserBalance,
  handleUnLockUser,
  handleReactiveUser,
  handleResetPin,
  handleCreatePin,
  handleDeactivate,
  handleCreateAdmin,
  handleGetListLanguageText,
  handleEditLanguageText,
  handleCreateLanguageText,
  handleDeleteLanguageText,
  handleGetListUserDeactivated,
  handleEditUserByAdmin,
  handleCheckAgent,
  sendOTP,
  verifyOTPService,
  verifyPINService,
  handleGetUserStatusHistoryDeactivate,
  handleVerifyPINByAgent,
  handleGetLanguageForBO,
  handleSetUserLanguage,
  handleCheckPasswordAdmin,
  handleGetActiveCryptoUsers,
  handeleUploadAvatar,
  handleGetKYCStatus,
  handleGetAllKYC,
  handleApplyKYCUserInfo,
  handleApplyKYCAddress,
  handleApplyKYCDocs,
  handleApplyKYCBankInfo,
  handleApplyKycDeclaration,
  handleGetUser,
  handleResetPassword,
  handleCheckUser
};
